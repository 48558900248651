import React, {useEffect, useState} from "react"
import {Redirect, useHistory } from "react-router-dom";
import {hasLoggedIn} from '../Utils.js'
import {useGlobalState} from 'state-pool';

const Logout = () => {

    // const [authenticated, setAuthenticated] = useState(false);
    const [authenticated, setAuthenticated] = useGlobalState("authenticated");
    const history = useHistory();

    // // This is run once when the component loads
    useEffect(() => {
      // setAuthenticated(hasLoggedIn());
      // let _authenticated = hasLoggedIn();

      if (authenticated) {
      // if (_authenticated) {
        console.log('user is logged in');
      } else {
        console.log('user is not logged in');
      }

      if (authenticated) {
      // if (_authenticated) {
        const loq = window.location
        const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/logout'

        fetch(
          url,
          {
            headers:{
              Accept: 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': "Bearer " + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
            },
            method: 'GET'
         }
        ).then(response =>{
          response.json()
          // response.text()
          .then(data => {
          // .then(txt => {

            console.log(data)

            localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY)
            setAuthenticated(false)

            console.log('\'sending\' you to Login ...')

            // // KO
            history.push("/login")

            // // KO
            // return <Redirect to="/login" />
         })
       })
     } else {
       
       history.push("/login")

     }

    }, [])

    return (
      <h1 className='text-center'>
          Logging you out ... 🚪
      </h1>
    )
};

export default Logout;
