import React, {useEffect} from 'react';
import {Redirect, useHistory } from "react-router-dom";
import logo from '../logo.svg';
import './Home.css';
import {useGlobalState} from 'state-pool';

const Home = () => {

  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const history = useHistory();

  // // This is run once when the component loads
  useEffect(() => {
    // // KO
    if (!authenticated) {

      console.log('not logged in ...');

      history.push("/login")
    }

    // // KO
    // return <Redirect to="/login" />
  }, [])

  return (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </header>
  </div>
)
}

export default Home;
