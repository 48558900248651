import React, {useState} from 'react';
import {Redirect, useHistory } from "react-router-dom";

const ResetPassword1 = () => {

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [email, setEmail] = useState('');
  const [showForm, setShowForm] = useState(true);

  const history = useHistory();

    /*
    // // This is run once when the component loads
    useEffect(() => {
      setAuthenticated(hasLoggedIn());
      // let _authenticated = hasLoggedIn();

      if (authenticated) {
      // if (_authenticated) {
        console.log('user is logged in');
      } else {
        console.log('user is not logged in');
      }
    }, []);
    */

  const handleFormSubmit = (e) => {
      e.preventDefault()

      setSuccessMessage('')
      setErrorMessage('')

      console.log(email)

      /* let auth =  localStorage.getItem('user_token');
      return fetch(url, {
       method: 'POST',
       headers:{
         Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + auth,
          },
     }).then(res => res.json()) */

     const loq = window.location
     const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/reset-password-1'

     var formData = new FormData()
     formData.append("email", email)

     fetch(
       url,
       {
         method: 'POST',
         body: formData
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

         console.log(data)

         if (data.status === 'ok') {

           setSuccessMessage(data.message)

           setShowForm(false)

         } else {

           if (data.message) {
             setErrorMessage(data.message)
           } else {
             // // if we got here, then there is
             // // likely a list of errors
             let txt = '';
             for (var key of Object.keys(data)) {
                 txt += key + " -> " + data[key]
             }
             setErrorMessage(txt)
           }

         }
   		})
   	})
  }

  return (
    <React.Fragment>
    {/* {authenticated ? (

      <Redirect to="/home" />

    ) : ( */}

      <div className='flex'>
          <div className='m-auto lg:w-1/4 md:w-1/2 sm:w-full bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10'>
              <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center text-blue-500'>Reset password</h1>

              {showForm && (
              <form onSubmit={handleFormSubmit} className="grid grid-flow-row ">

                  {/* <label htmlFor='email'>Email</label> */}
                  <input
                      type='email'
                      className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                      id='email'
                      placeholder='Email for your account'
                      onChange={e=>{setEmail(e.target.value)}}
                      value={email}
                  />

                  <button
                      className={`bg-green-500 py-2 px-4 text-sm text-white rounded-md border border-green focus:outline-none focus:border-green-dark`}
                  >Submit &#10148;</button>
              </form>
            ) }

              <div className='grid grid-flow-row mt-6'>
                  <div className='pt-2 mt-2 text-green-900 text-center'>{successMessage}</div>
                  <div className='pt-2 mt-2 text-red-900'>{errorMessage}</div>
              </div>
          </div>
      </div>

    {/* )} */}
     </React.Fragment>
  )
};

export default ResetPassword1;
