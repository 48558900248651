import React, {useEffect, useState} from "react";
import {
Link,
useHistory,
useParams,
useRouteMatch } from "react-router-dom";
import {useGlobalState} from 'state-pool';

const PlatformDetail = ({mode}) => {
  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");

  const { id } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();

  // // Set a dummy platform initially so React does not give warnings
  const [platform, setPlatform] = useState({});

  const [name, setName] = useState('');

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // // This is run once when the component loads
  useEffect(() => {

    console.log(`mode = ${mode}`)

    // // KO
    if (!authenticated) {

      console.log('not logged in ...');

      history.push("/login")
    } else {

      // // Only admin can see this component
      if (loggedInUser.role !== 'admin') {

        console.log('unauthorized ...')

        history.push('/logout')
      } else {

        if (mode !== "add") {

          console.log('fetching platform to edit ...')

          // // We are in edit mode, so
          // // get platform upon component load
          getUser(id)
        }

      }

    }
  }, [])

  useEffect(() => {

    // // Set these properties when platform changes
    // // Careful ... setting to undefined might yield warnings.
    if (platform.vvalue) {
      setName(platform.vvalue)
    }

    console.log('updated name from platform');

  }, [platform])

  const getUser = (eyed) => {

     const loq = window.location
     const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/platform/' + eyed

     fetch(
       url,
       {
         method: 'GET',
         headers:{
           Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
         },
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

          console.log(data)

          if (data.platform) {

            setPlatform(data.platform)

          } else {

            setErrorMessage(data.message)

          }
   		})
   	})
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setSuccessMessage('')
    setErrorMessage('')

     const loq = window.location
     const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/platform'

     var formData = new FormData()
     if (mode === 'add') {
       // // new record. id not required. must include email
       // formData.append("email", email)
     } else {
       // // existing record. requires id. can't update email
       formData.append("id", id)
     }

     formData.append("vvalue", name)

     fetch(
       url,
       {
         headers:{
            Accept: 'application/json',
            // 'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'multipart/form-data',
            // 'Content-Type': 'text/html',
            'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
         },
         // method: 'PUT',
         method: 'POST',
         body: formData
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

          console.log(data)

          if (data.data) {

            setPlatform(data.data)
            setSuccessMessage(data.status)

          } else {

            if (data.message) {
              setErrorMessage(data.message)
            } else {
              // // if we got here, then there is
              // // likely a list of errors
              let txt = '';
              for (var key of Object.keys(data)) {
                  txt += key + " -> " + data[key]
              }
              setErrorMessage(txt)
            }

          }
   		})
   	})
  }

  return (
      <div className='flex'>
          <div className='m-auto bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10'>

              <h1 className='text-2xl font-medium mt-4 mb-12 text-primary text-center text-blue-500'>
                {mode === 'add' ? <>Add platform</> : <>Edit platform</> }
              </h1>

              <form onSubmit={handleFormSubmit}>
                  <div>
                    <label htmlFor='vvalue'>Name</label>
                    <input
                        type='text'
                        className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                        id='vvalue'
                        placeholder='Name'
                        onChange={e=>{const { value } = e.target;setName(value)}}
                        value={name}
                    />
                  </div>
                  <div className='grid grid-flow-row mt-6'>
                      <button
                          className={`bg-green-500 py-2 px-4 text-sm text-white rounded-md border border-green focus:outline-none focus:border-green-dark`}
                      >Submit &#10148;</button>
                      <div className='pt-2 mt-2 text-green-900'>{successMessage}</div>
                      <div className='pt-2 mt-2 text-red-900'>{errorMessage}</div>
                  </div>
                  <div className='flex justify-start mt-2'>
                      <button className="text-gray-500" onClick={history.goBack}>&lsaquo; Go Back</button>
                  </div>
              </form>
          </div>
      </div>
  );
};

export default PlatformDetail;
