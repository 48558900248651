import React, {useEffect, useState} from "react"
import Login from './Login.js'
import {hasLoggedIn} from '../Utils.js'
import {useHistory} from 'react-router-dom'
import {useGlobalState} from 'state-pool';

const StickerUpload = () => {

  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");

  const history = useHistory();

  const [platforms, setPlatforms] = useState([]);
  const [platform, setPlatform] = useState('facebook');
  const [size, setSize] = useState(38);
  const [stickerImage, setStickerImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [postMessage, setPostMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  // // This is run once when the component loads
  useEffect(() => {
    // // KO
    if (!authenticated) {

      console.log('not logged in ...');

      history.push('/login')
    } else {

      console.log('fetching list ...')

      getPlatforms()

      console.log(`useEffect : loading ${stickerImage} with ${size} ...`);

      // // Force a change
      setStickerImage('facebook')
    }
  }, [])

  useEffect(() => {
    console.log(`useEffect : platform = ${platform}`);
  }, [platform]);

  useEffect(() => {
    console.log(`useEffect : size = ${size}`);
  }, [size]);

  useEffect(() => {
    console.log(`useEffect : stickerImage = ${stickerImage}`);
  }, [stickerImage]);

  const getPlatforms = () => {

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/platforms'

    fetch(
     url,
     {
       method:'GET',
       headers:{
         Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
       },
      }
    ).then(response =>{
     response.json()
     // response.text()
     .then(data => {
     // .then(txt => {

       console.log(data)
       // console.log('data.current_page = ' + data.current_page)
       // console.log('data.links = ' + data.links)
       // console.log('data.data = ' + data.data)

        if (data.platforms.data) {
          setPlatforms(data.platforms.data)
        }

    	})
    })
  }

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(document.getElementById('upload-sticker-template'));

    if (stickerImage === '') {
      setErrorMessage('Please select a file');

      return;
    }
    // formData.append("platform", platform);
    // formData.append("size", size);
    // formData.append("stickerImage", stickerImage);

    setLoading(true)
    setPostMessage('Uploading ...')

    const loq = window.location
    const target = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/sticker/upload'

    // // Submit form via Ajax
  	fetch(
      target,
      {
        headers:{
          Accept: 'application/json',
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
        },
        method:"POST",
        body: formData
  	  }
    ).then(response =>{
      response.json()
      // response.text()
      .then(data => {
      // .then(txt => {

        console.log(data);

        setLoading(false)
        setPostMessage(data.message)
  		})
  	});
  };

  return (
      <div className="lg:flex md:flex lg:justify-center md:justify-center">
        {/*
          <div className="m-auto bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10">
        */}

        <form
          id="upload-sticker-template"
          className="grid grid-flow-row lg:w-2/6 md:w-2/4 sm:w-full bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10"
          encType="multipart/form-data">

          <h1 className='text-2xl font-medium text-primary mt-0 mb-12 text-center text-blue-500'>
            Upload sticker PDF template
          </h1>

          <label className="text-gray-600">Platform</label>
          <select
            name="platform"
            value={platform}
            onChange={(e) => {setPlatform(e.target.value)}}
            className="border rounded p-1.5" >
            {
              platforms.map(function(platform, idx){
                const vv = platform.vvalue
                const __vv = vv.substring(0, 1).toUpperCase() + vv.substring(1);
                return <option key={platform.id} value={vv} >{__vv}</option>
              })
            }
            {/*
            <option value="facebook">Facebook</option>
            <option value="instagram">Instagram</option>
            <option value="twitter">Twitter</option>
            */}
          </select>

          <label className="text-gray-600 pt-4">SIZE</label>
          <select
            name="size"
            value={size}
            onChange={(e) => {setSize(e.target.value)}}
            className="border rounded p-1.5" >
            <option value="38">38 mm</option>
            <option value="50">50 mm</option>
          </select>

          {/* React hates the "value" attribute in "file" */}
          <input
            type="file"
            accept="application/pdf"
            name="stickerImage"
            onChange={(e) => {setStickerImage(e.target.files[0])}}
            className="pt-4 mb-4"
          />

          {errorMessage &&
            <div className="text-red-600 p-4">{errorMessage}</div>}

          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={submitForm}>Submit &#10148;</button>

          {postMessage &&
            <div className="text-center p-4">
              <span className={loading ? "text-gray-400" : "text-green-600"}>{postMessage}</span>
            </div>}
        </form>

      </div>
  )

}
export default StickerUpload
