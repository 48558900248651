import React, {useEffect, useState} from 'react';
import {
Redirect,
Link,
// Switch,
// Route,
generatePath,
useRouteMatch,
useHistory } from "react-router-dom";
import {useGlobalState} from 'state-pool';
import UserDetail from './UserDetail.js';

const Users = () => {

  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");

  const [users, setUsers] = useState([]);
  const history = useHistory();
  const { detailUrl } = useRouteMatch();

  // // This is run once when the component loads
  useEffect(() => {
    // // KO
    if (!authenticated) {

      console.log('not logged in ...');

      history.push('/login')
    } else {

      // // Only admin can see this component
      if (loggedInUser.role !== 'admin') {

        console.log('unauthorized ...')

        history.push('/logout')
      } else {

        console.log('fetching list ...')

        getUsers()
      }

    }
  }, [])

  const getUsers = () => {

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/users'

    fetch(
     url,
     {
       method:'GET',
       headers:{
         Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
       },
      }
    ).then(response =>{
     response.json()
     // response.text()
     .then(data => {
     // .then(txt => {

       console.log(data)
       // console.log('data.current_page = ' + data.current_page)
       // console.log('data.links = ' + data.links)
       // console.log('data.data = ' + data.data)

        if (data.users.data) {
          setUsers(data.users.data)
        }

    	})
    })
  }

  const deleteRecord = (event, id) => {
    event.preventDefault()

    console.log('deleteRecord : id = ' + id)

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/user/' + id

    fetch(
     url,
     {
       method:'DELETE',
       headers:{
         Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
       },
      }
    ).then(response =>{
     response.json()
     // response.text()
     .then(data => {
     // .then(txt => {

       console.log(data)

       // // Remove from internal list
       const newList = users.filter(i=> i.id !== id);
       setUsers(newList)

    	})
    })
  }

  return (
    <div className="flex flex-col justify-center items-center p-4">
      <div className="flex flex-col lg:w-1/2 md:w-2/3 w-full">
        <h1 className='text-left text-3xl font-medium text-primary mt-4 mb-12'>
            Users
        </h1>

        <Link className="text-right" title="Add" to="user-add"> &#10010; Add user </Link>
      </div>

        <table className="table-auto shadow-lg bg-white lg:w-1/2 md:w-2/3 w-full">
          <thead>
            <tr>
              <th className="bg-blue-100 border text-left px-8 py-4">Name</th>
              <th className="bg-blue-100 border text-left px-8 py-4">Role</th>
              <th className="bg-blue-100 border text-left px-8 py-4">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
        {
          users.map(function(user, idx){
            // let eyed = user.id

            // returns Nathan, then John, then Jane
            // return <tr key="{eyed}"><td><Link to={generatePath(`${detailUrl}/:id`, { id: eyed })}> {user.username} </Link> </td><td> {user.role} </td></tr>
            return <tr key={user.id}>
              <td className="border px-8 py-4"> {user.username} </td>
              <td className="border px-8 py-4"> {user.role} </td>
              <td className="text-right border px-8 py-4">
                <Link className="text-4xl" title="Edit" to={generatePath(`/user/:id`, { id: user.id })}> &#10000; </Link>
                {/* don't let the admin user delete themself */}
                {user.id !== 1 && <a className="text-4xl" title="Delete" href="#" onClick={(e) => deleteRecord(e, user.id)}> &#10006; </a>}
              </td>
            </tr>
          })
        }
          </tbody>
        </table>

      {/*
      <Switch>
        <Route
          path={`${detailUrl}/:id`}
          component={UserDetail}
        />
      </Switch>
      */}
    </div>
  )
}

export default Users;
