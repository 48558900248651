import React, {useCallback, useEffect, useState} from "react"
import { fabric } from 'fabric'
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import {useHistory} from 'react-router-dom'
import {useGlobalState} from 'state-pool'

const StickerCreate = () => {

  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");

  const history = useHistory();

  const [platforms, setPlatforms] = useState([]);
  const [sticker, setSticker] = useState('dummy');
  const [size, setSize] = useState(38);
  const [handle, setHandle] = useState('@SomeInfluencer');
  const [loading, setLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);
  const [canvasImage, setCanvasImage] = useState(false);
  const [canvasHandleText, setCanvasHandleText] = useState(false);
  const { selectedObjects, editor, onReady } = useFabricJSEditor()

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

  // // This is run once when the component loads
  useEffect(() => {
    // // KO
    if (!authenticated) {

      console.log('not logged in ...');

      history.push('/login')
    } else {

      console.log('fetching list ...')

      getPlatforms()
    }
  }, [])

  const getPlatforms = () => {

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/platforms'

    fetch(
     url,
     {
       method:'GET',
       headers:{
         Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
       },
      }
    ).then(response =>{
     response.json()
     // response.text()
     .then(data => {
     // .then(txt => {

       console.log(data)
       // console.log('data.current_page = ' + data.current_page)
       // console.log('data.links = ' + data.links)
       // console.log('data.data = ' + data.data)

        if (data.platforms.data) {
          setPlatforms(data.platforms.data)
        }

    	})
    })
  }

  const onAddImage = () => {
    const qvz = editor?.canvas

    if (!qvz) { return }

    // // Images come from the uploaded ones at the backend
    // const src = process.env.PUBLIC_URL + 'images/stickers/' + sticker + '.' + size + '.png'
    const loq = window.location
    // const src = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/images/stickers/' + sticker + '.' + size + '.png'
    const src = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/sticker/fetch/' + sticker + '/' + size

    /*
    const loadingTask = pdfjsLib.getDocument(src);
    loadingTask.promise.then(loadedPdf => {
      // setPdfRef(loadedPdf);

      loadedPdf && loadedPdf.getPage(1).then(function(page) {
        // const canvas = canvasRef.current;
        const qvz = editor?.canvas

        const viewport = page.getViewport({scale: 4})
        qvz.setHeight(viewport.height)
        qvz.setWidth(viewport.width)

        const context = qvz.getContext("2d")
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        }

        let renderTask = page.render(renderContext)

        renderTask.promise.then(function() {

          console.log('PDF rendered ?')

          const _qvz = editor?.canvas

          var bg = _qvz.toDataURL({
            // format: 'jpeg',
            format: 'png',
            quality: 1
          })

          fabric.Image.fromURL(bg, function(img) {

            console.log('Creating image from url')

            const _qvz = editor?.canvas

            img.scaleToHeight(_qvz.height)
            // canvas.setHeight(1123)
            // canvas.setWidth(1588)

            img.set({
              left: 0,
              top: 0,
              selectable: false,
              crossOrigin: 'Anonymous'
            })

            console.log(`onAddImage : img = ${img}`);

            // // Clear the canvas
            // _qvz.clear()

            // // Disappears upon click
            // _qvz.setBackgroundImage(img)

            // _qvz.add(img)

            // setCanvasImage(img)

            // // Add handle text automatically
            // // onAddHandleText()
          })

          // // Add handle text automatically
          // onAddHandleText()
        })

        // // Add handle text automatically
        // onAddHandleText()

      }, function (reason) {
        console.error(reason)
      })

      // // Add handle text automatically
      // onAddHandleText()

    })

    // // Add handle text automatically
    // onAddHandleText()
    */

    fetch(src, {
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
        },
        method: 'GET'
      })
      .then(resp => resp.blob())
      .then(blob => {

        // console.log(1)

        const urlFromBlob = window.URL.createObjectURL(blob)

        // console.log(`urlFromBlob = ${urlFromBlob}`)

        const image = new window.Image()
        image.classList.add('hide')
        image.src = urlFromBlob
        image.crossOrigin = 'Anonymous'
        image.addEventListener('load', () => {

          // console.log(3)

          // resolve(image);
          const qvz = editor?.canvas

          const context = qvz.getContext("2d");

          // // Clear the canvas
          qvz.clear()

          var imageFromElement = new fabric.Image(image, {
            left: 0,
            top: 0,
            selectable: false,
            crossOrigin: 'Anonymous'
          })

          // console.log(4)

          // // Add image
          // // Attempt 1
          qvz.add(imageFromElement)

          // console.log(5)

          // // Resize the canvas ?
          // // It's ok, our images are small anyway
          qvz.setHeight(imageFromElement.height);
          qvz.setWidth(imageFromElement.width);

          // console.log(6)

          // // Is the next line required ?
          // qvz.renderAll()

          // console.log(7)

          // // Add handle text automatically
          onAddHandleText();

        })
        image.addEventListener('error',
          // reject
          () => {

            // console.log(8)

          }
        );

      })
  }

  const degrees_to_radians = function(degrees) {
    return degrees * (Math.PI/180)
  }

  const onAddHandleText = () => {
    const qvz = editor?.canvas
    const www = qvz.width
    const hhh = qvz.height

    // // Construct arc
    // // text started at this angle relative to the center of the image
    const rot = 20
    // // distance from end of radius to end of image
    const padding = 50
    // // arc radius (from center of image ?)
    const arcRadius = www * 0.5 - padding
    const tmpX = arcRadius * Math.cos(degrees_to_radians(rot))
    const sss = www * 0.5 - tmpX
    const eee = www * 0.5 + tmpX
    const yyy = hhh * 0.5 + (arcRadius * Math.sin(degrees_to_radians(rot)))
    const xr = www * 0.55
    const yr = hhh * 0.9

    const arcPathString = "M " + sss + " " + yyy + " A " + xr + " " + yr + " 0 0 0 " + eee + " " + yyy
    const arc = new fabric.Path(
      arcPathString,
      {
        // stroke: 'blue',
        stroke: 'rgba(0,0,0,0)',
        // fill: 'orange'
        fill: 'rgba(0,0,0,0)',
        editable: false,
      })

    qvz.add(arc)

    // // After adding the arc to its parent,
    // // tell it to center itself in there
    // arc.center()

    const text2 = new fabric.Text(
      // "@WhatWhereWhen",
      handle,
      {
        // // Next line has no effect on paths :(
        // textAlign: 'center',
        // // For the next line, see https://stackoverflow.com/questions/41676054/how-to-add-fonts-to-create-react-app-based-projects
        fontFamily: 'FranklinGothicBookRegular',
        // fontFamily: 'sans-serif',
        fontSize: size,
        charSpacing:120,
        stroke: '#FFFFFF',
        fill: '#FFFFFF',
        path: arc,
        top: arc.top,
        left: arc.left,
        hasBorders: false,
        hasControls: false,
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
      })

    /* text2.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
    }); */

    setCanvasHandleText(text2)

    qvz.add(text2);

    // // Workaround for font not loading properly
    // // until after some event is fired.
    qvz.setActiveObject(text2);
    qvz.renderAll();
  }

  const dataURItoBlob = function ( b64 ) {
  	// convert base64/URLEncoded data component to raw binary data held in a string
  	var byteString;

  	if (b64.split(',')[0].indexOf('base64') >= 0)
  		byteString = atob(b64.split(',')[1]);
  	else
  		byteString = unescape(b64.split(',')[1]);

  	// separate out the mime component
  	var mimeString = b64.split(',')[0].split(':')[1].split(';')[0];

    console.log('mimeString = ', mimeString);

  	// write the bytes of the string to a typed array
  	var ia = new Uint8Array(byteString.length);
  	for (var i = 0; i < byteString.length; i++) {
  		ia[i] = byteString.charCodeAt(i);
  	}

    return new Blob([ia],
      {type:'image/octet-stream'});
      // {type:'image/jpeg'});
      // {type:mimeString});
  }

  const onGeneratePDF = () => {
    const qvz = editor?.canvas

    // // KO
    // var dataURL = qvz.toDataURL('image/jpeg', 1.0)
    // dataURL = dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")

    const dataURL = qvz.toDataURL('image/png')

    // console.log(dataURL);
    setLoading(true)
    setUploadMessage('Processing ...')
    setPdfUrl('')

  	// // Append to form
  	var blob = dataURItoBlob(dataURL)

  	var formData = new FormData()
    formData.append("imageBlob", blob, 'sticker.png');
    formData.append("platform", sticker);
    formData.append("size", size);
    formData.append("handle", handle);

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/sticker/generate'
    // const url = 'http://localhost/s/f/test-upload.php'

    // // Submit form via Ajax
  	fetch(
      url,
      {
        headers:{
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data',
          // 'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
        },
        method: 'POST',
        body: formData
  	  }
    ).then(response =>{
      response.json()
      // response.text()
      .then(data => {
      // .then(txt => {

        console.log(data);

  			// document.getElementById("result").innerHTML = txt;
        // setUploadMessage(txt)
        setLoading(false)
        setUploadMessage(data.message)

        setPdfUrl(loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/pdf/' + data.id)
  		})
  	})
  }

  // // This is run once when the component loads
  useEffect(() => {
    console.log(`useEffect : setting sticker to ${sticker} in order to trigger render logic`);
    // // Force a change
    setSticker('facebook');
    // onAddImage()
  }, []);

  useEffect(() => {

    console.log(`useEffect : sticker = ${sticker}`);

    onAddImage()
  }, [sticker])

  useEffect(() => {

    console.log(`useEffect : size = ${size}`);

    onAddImage()
  }, [size])

  useEffect(() => {

    console.log(`useEffect : canvasImage = ${canvasImage}`);

    const qvz = editor?.canvas

    if (qvz && canvasImage) {
      // onAddHandleText()

      // qvz.clear()

      // qvz.setBackgroundImage(canvasImage).renderAll();
      // qvz.add(canvasImage)
    }
  }, [canvasImage])

  /*
  useEffect(() => {
    console.log(`useEffect : handle = ${handle}`);
  }, [handle]);
  */

  const updateCanvas = useCallback(() => {

    console.log(`updateCanvas: handle = ${handle}`);

    // // Update canvas with latest handle
    const qvz = editor?.canvas

    if (qvz && canvasHandleText) {
      // // Pad string
      var actualHandle = handle
      const maxLength = 19
      const hl = handle.length
      const randomMultiplier = 0.75
      if (hl < maxLength) {
        const diff = Math.ceil((maxLength - hl) * randomMultiplier)

        // console.log(diff)

        for (var i = 0; i < diff; i++) {
          actualHandle = " " + actualHandle
        }
      }
      // canvasHandleText.set('text', handle)
      canvasHandleText.set('text', actualHandle)
      qvz.renderAll()
    }
  }, [handle])

  useEffect(updateCanvas, [handle]);


  return (<div>
      {/* <h2>StickerCreator</h2> */}

      <div className="flex flex-wrap justify-center">

      {/* <div className="lg:w-2/4 p-4"> */}
      <div className="lg:w-2/6 md:w-2/4 w-full">
        <div className="grid grid-flow-row w-full bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10">

            <h1 className='text-2xl font-medium text-primary mt-0 mb-12 text-center text-blue-500'>
              Create sticker
            </h1>

            <label className="text-gray-600">Platform</label>
            <select
              name="sticker"
              value={sticker}
              onChange={(e) => {setSticker(e.target.value);}}
              className="border rounded p-1.5" >
              {
                platforms.map(function(platform, idx){
                  const vv = platform.vvalue
                  const __vv = vv.substring(0, 1).toUpperCase() + vv.substring(1);
                  return <option key={platform.id} value={vv} >{__vv}</option>
                })
              }
              {/*
              <option value="facebook">Facebook</option>
              <option value="instagram">Instagram</option>
              <option value="twitter">Twitter</option>
              */}
            </select>

            <label className="text-gray-600 pt-4">Size</label>
            <select
              name="size"
              value={size}
              onChange={(e) => {setSize(e.target.value);}}
              className="border rounded p-1.5" >
              <option value="38">38 mm</option>
              <option value="50">50 mm</option>
            </select>

            <label className="text-gray-600 pt-4">Handle</label>
            <input
              type="text"
              name="handle"
              value={handle}
              maxLength="19"
              onChange={(e) => {setHandle(e.target.value);}}
              className="border rounded p-1" />

            <button
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-4 rounded"
              onClick={onGeneratePDF}>Generate PDF &#10148;</button>

            {uploadMessage &&
            <div className="text-center p-4">
              <span className={loading ? "text-gray-400" : "text-green-600"}>{uploadMessage}</span>
            </div>}

            {pdfUrl &&
            <div className="text-center text-blue-600">
              <a href={pdfUrl} target="_blank" rel="noreferrer" >&#9886; Download PDF &#9887;</a>
            </div>}
        </div>
      </div>

      <div className="lg:w-2/4 p-4">
        {/* <div className="grid grid-flow-col-dense grid-cols-5">
          <button className="mr-4" onClick={onAddImage}>Add Image</button>
          <button className="mr-4" onClick={onAddHandleText}>Add Handle Text</button>
          <button className="mr-4" onClick={onAddHandleText}>Add Handle Text</button>
        </div> */}
        <FabricJSCanvas onReady={onReady} />
      </div>

    </div>

  </div>)
}

export default StickerCreate
