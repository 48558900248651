import {NavLink, Link} from 'react-router-dom'
import {useEffect, useState} from "react";
import {useGlobalState} from 'state-pool';

// const Header = () => (
const Header = () => {

  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser] = useGlobalState("loggedInUser");
  const [menuVisible, setMenuVisible] = useState(false);

  // // So much work just to add a class.
  const defaultKlass = 'w-full block lg:flex lg:items-center lg:w-auto '
  const [klass, setKlass] = useState(defaultKlass);

  const toggleMenu = () => {

    console.log('toggleMenu');

    setMenuVisible(!menuVisible)
  };

  useEffect(() => {
    if (menuVisible) {
      setKlass(defaultKlass + 'flex-grow')
    } else {
      setKlass(defaultKlass + 'hidden')
    }

    console.log('useEffect => menuVisible');

  }, [menuVisible])

return (
  <nav className="flex items-center justify-between flex-wrap bg-gray-100 p-6">
    <div className="flex items-center flex-shrink-0 text-gray-500 mr-6">
      <img src="/logo.png" />
      {/*
        <svg class="fill-current h-8 w-8 mr-2" width="54" height="54" viewBox="0 0 54 54" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z"/></svg>
        <span className="font-semibold text-xl tracking-tight">&#10040; Sticker Generator</span>
      */}
    </div>
    <div className="block lg:hidden">
      <button
        className="flex items-center px-3 py-2 border rounded border-gray-400 hover:border-white"
        onClick={toggleMenu}>
        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
      </button>
    </div>
    <div className={klass}>
      <div className="text-sm lg:flex-grow">
        {(authenticated && loggedInUser.role === 'admin') && (
          <>
            <NavLink to="/users" className="block p-2 m-3 rounded lg:inline-block hover:bg-gray-400" activeClassName="bg-gray-200"><span>Users</span></NavLink>
            <NavLink to="/platforms" className="block p-2 m-3 rounded lg:inline-block hover:bg-gray-400" activeClassName="bg-gray-200"><span>Platforms</span></NavLink>
          </>
        )}
        {authenticated && (
          <>
            <NavLink to="/sticker-upload" className="block p-2 m-3 rounded lg:inline-block hover:bg-gray-400" activeClassName="bg-gray-200"><span>Upload</span></NavLink>
            <NavLink to="/sticker-create" className="block p-2 m-3 rounded lg:inline-block hover:bg-gray-400" activeClassName="bg-gray-200"><span>Create</span></NavLink>
          </>
        )}
      </div>
      <div>
      {authenticated ? (
        <>
          <span className="inline-block mx-2 px-4 py-2 leading-none rounded text-gray-900 bg-yellow-300">👤 {loggedInUser.username}</span>
          <Link to="/logout" className="block p-2 m-3 rounded lg:inline-block hover:bg-gray-400" ><span>Logout</span></Link>
        </>
      ) : (
        <Link to="/login" className="inline-block text-sm px-4 py-2 leading-none border rounded border-gray-400 hover:border-transparent hover:bg-gray-200 mt-4 lg:mt-0"><span>Login</span></Link>
      )}
      </div>
    </div>
  </nav>
) }
export default Header;
