import React, {useState} from 'react';
import Home from './Home.js';
import {Link, Redirect, useHistory } from "react-router-dom";
// import {hasLoggedIn} from '../Utils.js'
import {useGlobalState} from 'state-pool';

const Login = () => {

  // const [authenticated, setAuthenticated] = useState(false);
  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();

    /*
    // // This is run once when the component loads
    useEffect(() => {
      setAuthenticated(hasLoggedIn());
      // let _authenticated = hasLoggedIn();

      if (authenticated) {
      // if (_authenticated) {
        console.log('user is logged in');
      } else {
        console.log('user is not logged in');
      }
    }, []);
    */

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setErrorMessage('')

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/login'

    var formData = new FormData()
    formData.append("email", email)
    formData.append("password", password)

     fetch(
       url,
       {
         method: 'POST',
         body: formData
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

          // console.log('process.env.REACT_APP_TOKEN_KEY = ' + process.env.REACT_APP_TOKEN_KEY)
          console.log(data)

          let _authenticated = false;

          if (data.token) {
            localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, data.token)

            setAuthenticated(true)
            // store.setState("authenticated", true);

            getProfile()

            let home = '/sticker-upload'

            console.log(`sending you to ${home}`)

            history.push(home)
          } else {
            setAuthenticated(false)

            setErrorMessage(data.message)
          }

          // // KO
          // return <Redirect to="/home" />
   		})
   	})
  }

  const getProfile = () => {

    const loq = window.location
    const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/profile'

    fetch(
     url,
     {
       method:'GET',
       headers:{
         Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
       },
      }
    ).then(response =>{
     response.json()
     // response.text()
     .then(data => {
     // .then(txt => {

        console.log(data)

        if (data.user) {
          setLoggedInUser(data.user)
        }

    	})
    })
  }

  return (
    <React.Fragment>
    {/* {authenticated ? (

      <Redirect to="/home" />

    ) : ( */}

      <div className='flex'>
          <div className='m-auto lg:w-1/4 md:w-1/2 sm:w-full bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10'>
              <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center text-blue-500'>🔐 Log in</h1>

              <form onSubmit={handleFormSubmit} className="grid grid-flow-row ">
                  <div>
                      {/* <label htmlFor='email'>Email</label> */}
                      <input
                          type='email'
                          id='email'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          placeholder='Your Email'
                          onChange={e=>{setEmail(e.target.value)}}
                          value={email}
                      />
                  </div>
                  <div>
                      {/* <label htmlFor='password'>Password</label> */}
                      <input
                          type='password'
                          id='password'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          placeholder='Your Password'
                          onChange={e=>{setPassword(e.target.value)}}
                          value={password}
                      />
                  </div>

                  <button
                      className={`bg-green-600 mt-6 py-2 px-4 text-sm text-white rounded-md border border-green focus:outline-none focus:border-green-dark`}
                      >Submit &#10148;</button>

                  <div className='pt-2 mt-2 text-red-900'>{errorMessage}</div>

                  <Link to="/reset-password-1"><span className="inline-block text-gray-400 hover:text-gray-900">Forgot password ?</span></Link>

              </form>
          </div>
      </div>

    {/* )} */}
     </React.Fragment>
  )
};

export default Login;
