import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import Header from './components/Header.js';
import Home from './components/Home.js';
import About from './components/About.js';
import StickerUpload from './components/StickerUpload.js';
import StickerCreate from './components/StickerCreate.js';
import Users from './components/Users.js';
import UserDetail from './components/UserDetail.js';
import Platforms from './components/Platforms.js';
import PlatformDetail from './components/PlatformDetail.js';
import Login from './components/Login.js';
import Logout from './components/Logout.js';
import ResetPassword1 from './components/ResetPassword1.js';
import ResetPassword2 from './components/ResetPassword2.js';
import {store} from 'state-pool';

const App = () => {

  // // Store the global variable.
  // // Courtesy of the 'state-pool' library.
  store.setState("authenticated", false);
  store.setState("loggedInUser", {});

  return (
<Router>

  <Header />

  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/about"  >
      <About />
    </Route>
    <Route exact path="/users"  >
      <Users />
    </Route>
    <Route exact path="/platforms"  >
      <Platforms />
    </Route>
    <Route exact path="/sticker-upload" >
      <StickerUpload />
    </Route>
    <Route exact path="/sticker-create" >
      <StickerCreate />
    </Route>
    <Route exact path="/logout">
      <Logout />
    </Route>

    <Route exact path="/login">
      <Login />
    </Route>

    <Route exact path="/reset-password-1">
      <ResetPassword1 />
    </Route>
    <Route exact path="/reset-password-2/:hash">
      <ResetPassword2 />
    </Route>

    <Route exact path="/user/:id"  >
      <UserDetail />
    </Route>
    <Route exact path="/user-add"  >
      <UserDetail mode="add" />
    </Route>

    <Route exact path="/platform/:id"  >
      <PlatformDetail />
    </Route>
    <Route exact path="/platform-add"  >
      <PlatformDetail mode="add" />
    </Route>
  </Switch>

</Router>
  )
}
export default App;
