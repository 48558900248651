import React, {useState} from 'react';
import {Redirect, useHistory, useParams } from "react-router-dom";

const ResetPassword2 = () => {

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showForm, setShowForm] = useState(true);

  const { hash } = useParams();

  const history = useHistory();

    /*
    // // This is run once when the component loads
    useEffect(() => {
      setAuthenticated(hasLoggedIn());
      // let _authenticated = hasLoggedIn();

      if (authenticated) {
      // if (_authenticated) {
        console.log('user is logged in');
      } else {
        console.log('user is not logged in');
      }
    }, []);
    */

  const handleFormSubmit = (e) => {
      e.preventDefault()

      setSuccessMessage('')
      setErrorMessage('')

      /* let auth =  localStorage.getItem('user_token');
      return fetch(url, {
       method: 'POST',
       headers:{
         Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': "Bearer " + auth,
          },
     }).then(res => res.json()) */

     if (password !== password2) {
       setErrorMessage('Passwords must match')
       return
     }

     const loq = window.location
     const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/reset-password-2'

     var formData = new FormData()
     formData.append("reset_hash", hash)
     formData.append("password", password)

     fetch(
       url,
       {
         headers:{
            Accept: 'application/json',
            // 'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'multipart/form-data',
            // 'Content-Type': 'text/html',
            'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
         },
         method: 'POST',
         body: formData
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

         console.log(data)

         if (data.status === 'ok') {

           setSuccessMessage(data.message)

           setShowForm(false)

         } else {

           if (data.message) {
             setErrorMessage(data.message)
           } else {
             // // if we got here, then there is
             // // likely a list of errors
             let txt = '';
             for (var key of Object.keys(data)) {
                 txt += key + " -> " + data[key]
             }
             setErrorMessage(txt)
           }

         }
   		})
   	})
  }

  return (
    <React.Fragment>
    {/* {authenticated ? (

      <Redirect to="/home" />

    ) : ( */}

      <div className='flex'>
          <div className='m-auto lg:w-1/4 md:w-1/2 sm:w-full bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10'>
              <h1 className='text-2xl font-medium text-primary mt-4 mb-12 text-center text-blue-500'>Reset password</h1>

              {showForm && (
                <form onSubmit={handleFormSubmit} className="grid grid-flow-row ">
                  <div>
                      <label htmlFor='password'>Password</label>
                      <input
                          type='password'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          id='password'
                          placeholder='At least 6 characters'
                          onChange={e=>{setPassword(e.target.value)}}
                          value={password}
                      />
                  </div>
                  <div>
                      <label htmlFor='password'>Confirm password above</label>
                      <input
                          type='password'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          id='password2'
                          onChange={e=>{setPassword2(e.target.value)}}
                          value={password2}
                      />
                  </div>

                  <button className="bg-green-500 mt-2 py-2 px-4 text-sm text-white rounded-md border border-green focus:outline-none focus:border-green-dark" >Submit &#10148;</button>
                </form>
              ) }

              <div className='grid grid-flow-row mt-2'>
                  <div className='pt-2 text-green-900 text-center'>{successMessage}</div>
                  <div className='pt-2 text-red-900'>{errorMessage}</div>
              </div>
          </div>
      </div>

    {/* )} */}
     </React.Fragment>
  )
};

export default ResetPassword2;
