import React, {useEffect, useState} from "react";
import {
Link,
useHistory,
useParams,
useRouteMatch } from "react-router-dom";
import {useGlobalState} from 'state-pool';

const UserDetail = ({mode}) => {
  const [authenticated, setAuthenticated] = useGlobalState("authenticated");
  const [loggedInUser, setLoggedInUser] = useGlobalState("loggedInUser");

  const { id } = useParams();
  const { url } = useRouteMatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [active, setActive] = useState(1);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // // This is run once when the component loads
  useEffect(() => {

    console.log(`mode = ${mode}`)

    // // KO
    if (!authenticated) {

      console.log('not logged in ...');

      history.push("/login")
    } else {

      // // Only admin can see this component
      if (loggedInUser.role !== 'admin') {

        console.log('unauthorized ...')

        history.push('/logout')
      } else {

        console.log(`fetching user ${id} to edit ...`)

        if (mode !== "add") {
          // // We are in edit mode, so
          // // get user upon component load
          getUser(id)
        }

      }

    }
  }, [])

  const getUser = (eyed) => {

     const loq = window.location
     const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/user/' + eyed

     fetch(
       url,
       {
         method: 'GET',
         headers:{
           Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
         },
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

          console.log(data)

          if (data.user) {

            // setUser(data.user)
            const user = data.user

            setEmail(user.email)
            setUsername(user.username)
            setRole(user.role)
            // setActive(user.active)
            setActive(user.is_active)

          } else {

            setErrorMessage(data.message)

          }
   		})
   	})
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()

    setSuccessMessage('')
    setErrorMessage('')

    // let email = e.target.elements.email?.value
    // let password = e.target.elements.password?.value
    // let password2 = e.target.elements.password2?.value
    // let role = e.target.elements.role?.value
    // let username = e.target.elements.username?.value

    if (password !== password2) {
      setErrorMessage('Passwords must match')
      return
    }

    console.log(id, role, username, password)

     const loq = window.location
     const url = loq.protocol+'//'+loq.hostname + process.env.REACT_APP_BACKEND_BASE_URL + '/user'

     var formData = new FormData()
     if (mode === 'add') {
       // // new record. id not required. must include email
       formData.append("email", email)
     } else {
       // // existing record. requires id. can't update email
       formData.append("id", id)
     }
     /*
     if (id) {
       // // existing record
       formData.append("id", id)
     } else {
       // // new record
       formData.append("email", email)
     }
     */
     if (password) {
       formData.append("password", password)
     }
     formData.append("active", active)
     formData.append("role", role)
     formData.append("username", username)

     fetch(
       url,
       {
         headers:{
            Accept: 'application/json',
            // 'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Content-Type': 'multipart/form-data',
            // 'Content-Type': 'text/html',
            'Authorization': 'Bearer ' + localStorage.getItem(process.env.REACT_APP_TOKEN_KEY),
         },
         // method: 'PUT',
         method: 'POST',
         body: formData
   	  }
     ).then(response =>{
       response.json()
       // response.text()
       .then(data => {
       // .then(txt => {

          console.log(data)

          if (data.data) {

            setSuccessMessage(data.status)

          } else {

            if (data.message) {
              setErrorMessage(data.message)
            } else {
              // // if we got here, then there is
              // // likely a list of errors
              let txt = '';
              for (var key of Object.keys(data)) {
                  txt += key + " -> " + data[key]
              }
              setErrorMessage(txt)
            }

          }
   		})
   	})
  }

  return (
      <div className='flex'>
          <div className='m-auto bg-gradient-to-b from-blue-100 to-blue-200 rounded-lg shadow-lg py-10 px-16 mt-10'>

              <h1 className='text-2xl font-medium mt-4 mb-12 text-primary text-center text-blue-500'>
                {mode === 'add' ? <>Add user</> : <>Edit user</> }
              </h1>

              <form onSubmit={handleFormSubmit}>
                  <div>
                      <label htmlFor='email'>Email</label>
                      {mode === 'add' ? (
                      <input
                          type='email'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          id='email'
                          placeholder='Email'
                          onChange={e=>{setEmail(e.target.value)}}
                          value={email}
                      /> ) : (
                      <div className="text-gray-600 mb-3">{email}</div>
                    )}
                  </div>
                  <div>
                      <label htmlFor='password'>Password</label>
                      <input
                          type='password'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          id='password'
                          placeholder='Leave blank if not changing'
                          onChange={e=>{setPassword(e.target.value)}}
                          value={password}
                      />
                  </div>
                  <div>
                      <label htmlFor='password'>Confirm password above</label>
                      <input
                          type='password'
                          className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                          id='password2'
                          placeholder='Leave blank if not changing'
                          onChange={e=>{setPassword2(e.target.value)}}
                          value={password2}
                      />
                  </div>
                  <div>
                    <label htmlFor='username'>Username</label>
                    <input
                        type='text'
                        className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                        id='username'
                        placeholder='Username'
                        onChange={e=>{setUsername(e.target.value)}}
                        value={username}
                    />
                    {/* onChange={handleInputChange} */}
                  </div>
                  <div>
                    <label htmlFor='role'>Role</label>
                    {/* don't let the admin user change their role */}
                    {(id == 1) ? (
                    <div className="text-gray-600 inline-block ml-3">{role}</div>
                    ) : (
                    <select
                        className={`w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:border-transparent`}
                        id='role'
                        name="role"
                        onChange={e=>{setRole(e.target.value)}}
                        value={role} >
                        <option>admin</option>
                        <option>user</option>
                    </select>
                    )}
                  </div>
                  <div className="inline-flex items-center">
                      <span>Active</span>
                      {/* don't let the admin user change their status */}
                      {(id == 1) ? (
                      <div className="text-gray-600 ml-3">{active === 1 ? 'Yes' : 'No'}</div>
                      ) : (
                      <input
                        className="checked:bg-blue-600 checked:border-transparent h-7 w-7 ml-2"
                        type="checkbox"
                        id="active"
                        name="active"
                        onChange={e=>{setActive(e.target.checked ? 1 : 0)}}
                        checked={active}
                        />
                      )}
                  </div>
                  <div className='grid grid-flow-row mt-6'>
                      <button
                          className={`bg-green-500 py-2 px-4 text-sm text-white rounded-md border border-green focus:outline-none focus:border-green-dark`}
                      >Submit &#10148;</button>
                      <div className='pt-2 mt-2 text-green-900'>{successMessage}</div>
                      <div className='pt-2 mt-2 text-red-900'>{errorMessage}</div>
                  </div>
                  <div className='flex justify-start mt-2'>
                      <button className="text-gray-500" onClick={history.goBack}>&lsaquo; Go Back</button>
                  </div>
              </form>
          </div>
      </div>
  );
};

export default UserDetail;
